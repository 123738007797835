import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {registrationDefaultValues} from '../../utils/formDefaultValues/registrationDefaultValues'
import {registrationFormSchema} from '../../utils/formSchemas/registrationFormSchema'
import {useGetLocationsQuery} from '../../utils/queries/locations'
import {getReCaptchaToken, reCaptchaInit} from '../../utils/recaptchaApi'
import {District, Municipality, Parish} from '../../utils/types/locations'
import {useCreateSimpleRegistrationQuery} from '../../utils/queries/registrations'
import {SimpleRegistrationInput} from '../../utils/types/registrations'
import {Link, useSearchParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function Registration() {
  const {data: locationsData} = useGetLocationsQuery()
  const {mutate: createRegistration, isLoading: createRegistrationLoading} =
    useCreateSimpleRegistrationQuery()

  const [municipalities, setMunicipalities] = useState<Array<Municipality> | undefined>([])
  const [parishes, setParishes] = useState<Array<Parish> | undefined>([])

  const [searchParams] = useSearchParams()

  const formik = useFormik({
    initialValues: registrationDefaultValues(),
    validationSchema: registrationFormSchema(),
    onSubmit: async (values) => {
      const input = {
        full_name: `${values.firstName} ${values.lastName}`,
        email: values.email,
        parish: values.parish,
        tracking_link: searchParams.get('t') !== null ? searchParams.get('t') : '',
      } as SimpleRegistrationInput

      const reCaptchaToken = await getReCaptchaToken()

      createRegistration({registrationData: input, reCaptchaToken: reCaptchaToken})
    },
  })

  useEffect(() => {
    reCaptchaInit()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='registrationForm'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <Link to='/'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/chegaLogo.png')}
            className='logo-default w-lg-175px w-125px mb-4'
          />
        </Link>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Ligue-se ao Partido CHEGA!</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>
          Fique a par das nossas atividades!
        </div>
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Full Name */}
      <div className='fv-row mb-3 row'>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Primeiro Nome</label>
          <input
            placeholder='Primeiro Nome'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control form-control-white',
              {
                'is-invalid': formik.touched.firstName && formik.errors.firstName,
              },
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              }
            )}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Apelido</label>
          <input
            placeholder='Apelido'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastName')}
            className={clsx(
              'form-control form-control-white',
              {
                'is-invalid': formik.touched.lastName && formik.errors.lastName,
              },
              {
                'is-valid': formik.touched.lastName && !formik.errors.lastName,
              }
            )}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastName}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group Full Name */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Endereço de Email</label>
        <input
          placeholder='Email'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-white',
            {
              'is-invalid': formik.touched.email && formik.errors.email,
            },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group Email */}

      <div className='fv-row mb-3 row'>
        {/* begin::Form group District */}
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Distrito</label>
          <select
            {...formik.getFieldProps('district')}
            onChange={(ev) => {
              formik.handleChange(ev)
              setParishes([])
              const municipalitiesData = locationsData?.data.filter(
                (d: District) => d.code === ev.target.value
              )[0].municipalities
              setMunicipalities(municipalitiesData)
            }}
            className={clsx(
              'form-select form-select-white',
              {
                'is-invalid': formik.touched.district && formik.errors.district,
              },
              {
                'is-valid': formik.touched.district && !formik.errors.district,
              }
            )}
          >
            <option value='' disabled>
              Selecione...
            </option>
            {locationsData?.data.map((d: District) => (
              <option key={d.code} value={d.code}>
                {d.name}
              </option>
            ))}
          </select>
          {formik.touched.district && formik.errors.district && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.district}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group District */}

        {/* begin::Form group Municipality */}
        <div className='col-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Concelho</label>
          <select
            {...formik.getFieldProps('municipality')}
            onChange={(ev) => {
              formik.handleChange(ev)
              const parishesData = municipalities?.filter(
                (m: Municipality) => m.code === ev.target.value
              )[0].parishes
              setParishes(parishesData)
            }}
            className={clsx(
              'form-select form-select-white',
              {
                'is-invalid': formik.touched.municipality && formik.errors.municipality,
              },
              {
                'is-valid': formik.touched.municipality && !formik.errors.municipality,
              }
            )}
          >
            <option value='' disabled>
              Selecione...
            </option>
            {municipalities?.map((m: Municipality) => (
              <option key={m.code} value={m.code}>
                {m.name}
              </option>
            ))}
          </select>
          {formik.touched.municipality && formik.errors.municipality && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.municipality}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group Municipality */}
      </div>

      {/* begin::Form group Parish */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6'>Freguesia</label>
        <select
          {...formik.getFieldProps('parish')}
          className={clsx(
            'form-select form-select-white',
            {
              'is-invalid': formik.touched.parish && formik.errors.parish,
            },
            {
              'is-valid': formik.touched.parish && !formik.errors.parish,
            }
          )}
        >
          <option value='' disabled>
            Selecione...
          </option>
          {parishes?.map((p: Parish) => (
            <option key={p.code} value={p.code}>
              {p.name}
            </option>
          ))}
        </select>
        {formik.touched.parish && formik.errors.parish && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.parish}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group Parish */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            Aceito a
            <a
              href='https://partidochega.pt/index.php/politicaprivacidadech/'
              target='_blank'
              className='ms-1 link-primary'
              rel='noreferrer'
            >
              Política de Privacidade do Partido CHEGA!
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!createRegistrationLoading && <span className='indicator-label'>Registar</span>}
          {createRegistrationLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
