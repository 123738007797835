import {Helmet} from "react-helmet";
import React, {Fragment, ReactNode} from "react";

export function Head({children}: {children: ReactNode}) {
    return <Fragment>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Já CHEGA! | Junte-se a nós!</title>
            <meta name="description" content="Junte-se ao Partido CHEGA!" />
        </Helmet>
        {children}
    </Fragment>
}