import axios from 'axios'
import {SimpleRegistrationInput} from '../types/registrations'

const URL = `${process.env.REACT_APP_API_URL}api/simple-registrations/`

export function createRegistration(
  registrationData: SimpleRegistrationInput,
  reCaptchaToken: string
) {
  return axios.post(URL, registrationData, {headers: {'Recaptcha-Token': reCaptchaToken}})
}

export function verifyEmail(registrationId: string) {
  return axios.put(
    `${URL}${registrationId}/verify-email/`,
    {},
  )
}