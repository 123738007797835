export function registrationDefaultValues() {
  return {
    firstName: '',
    lastName: '',
    email: '',
    district: '',
    municipality: '',
    parish: '',
    acceptTerms: false,
  }
}
