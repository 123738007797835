import Swal from 'sweetalert2'

export function successResponseHandler(
  title: string,
  message: string,
  buttonMessage: string,
  showConfirmButton: boolean = true
) {
  return Swal.fire({
    icon: 'success',
    title: title,
    text: message,
    confirmButtonText: buttonMessage,
    showConfirmButton: showConfirmButton,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false
  })
}

export function errorResponseHandler(message: string, buttonMessage: string) {
  return Swal.fire({
    icon: 'error',
    title: 'Ocorreu um erro!',
    text: message,
    confirmButtonText: buttonMessage,
  })
}
