import {useMutation} from 'react-query'
import {SimpleRegistrationInput} from '../types/registrations'
import {createRegistration, verifyEmail} from '../requests/registration'
import {errorResponseHandler, successResponseHandler} from '../apiResponses/responseHandler'
import {AxiosError} from 'axios'

export function useCreateSimpleRegistrationQuery() {
  return useMutation({
    mutationFn: (data: {registrationData: SimpleRegistrationInput; reCaptchaToken: string}) =>
      createRegistration(data.registrationData, data.reCaptchaToken),
    onSuccess: () => {
      successResponseHandler(
        'Registo criado com sucesso!',
        'O seu registo foi criado com sucesso. Consulte o seu email para validar o seu endereço e terminar o registo.',
        'Compreendi',
        false
      )
          .then(() => {
            window.location.reload()
          })
    },
    onError: () => {
      errorResponseHandler(
        'Ocorreu um erro na criação do seu registo. Por favor verifique que todos os campos estão preenchidos corretamente.',
        'Continuar'
      )
    },
  })
}

export function useVerifyEmailQuery() {
  return useMutation({
    mutationFn: (registrationId: string) =>
      verifyEmail(registrationId),
    onError: (error: AxiosError) => {
      errorResponseHandler(
        error.response?.status === 409
          ? 'Email já verificado'
          : 'Não foi possível verificar o seu email. Por favor tente mais tarde',
        'Continuar'
      )
    },
  })
}