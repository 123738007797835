import ReactRecaptcha3 from 'react-google-recaptcha3'

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY

export function reCaptchaInit() {
  return ReactRecaptcha3.init(SITE_KEY)
}

export function getReCaptchaToken() {
  return ReactRecaptcha3.getToken()
}
