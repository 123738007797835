import {Navigate, Route, Routes} from 'react-router-dom'
import {AuthLayout} from '../../layouts/auth/AuthLayout'
import {Registration} from '../../pages/auth/Registration'
import {VerifyEmail} from "../../pages/auth/VerifyEmail";

export function AuthRoutes() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='/' element={<Registration />} />
        <Route path='verify-email/:registrationId' element={<VerifyEmail />} />
        <Route path='*' element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  )
}
