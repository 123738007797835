import {Link, useParams} from 'react-router-dom'
import {useVerifyEmailQuery} from '../../utils/queries/registrations'
import React, {useEffect} from 'react'
import {Spinner} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function VerifyEmail() {
  const params = useParams()

  const {mutate: verifyEmail, isSuccess, isError} = useVerifyEmailQuery()

  useEffect(() => {
    verifyEmail(params.registrationId as string)
  }, [])

  return (
    <div className='w-100'>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <Link to='/'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/chegaLogo.png')}
            className='logo-default w-lg-175px w-125px mb-4'
          />
        </Link>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Validação de e-mail de registo</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      <div className='d-flex flex-center'>
        {!isSuccess && !isError && (
          <div className='btn btn-primary cursor-default disabled'>
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              className='me-2'
            />
            A validar...
          </div>
        )}
        {isSuccess && (
          <Link to={'/'} className={'btn btn-success cursor-default'}>
            <i className='fa fa-check'></i>Validado com sucesso
          </Link>
        )}
        {isError && (
          <div className='btn btn-danger cursor-default'>
            <i className='fa fa-times'></i>Ocorreu um erro
          </div>
        )}
      </div>
    </div>
  )
}