import * as Yup from 'yup'

export function registrationFormSchema() {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 100 caracteres')
      .required('Campo obrigatório'),
    lastName: Yup.string()
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 100 caracteres')
        .required('Campo obrigatório'),
    email: Yup.string()
      .email('Email inválido')
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 100 caracteres')
      .required('Campo obrigatório'),
    district: Yup.string().required('Campo obrigatório'),
    municipality: Yup.string().required('Campo obrigatório'),
    parish: Yup.string().required('Campo obrigatório'),
    acceptTerms: Yup.bool().required('Deve aceitar os termos e condições'),
  })
}
